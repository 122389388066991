import React, { forwardRef } from "react"
import { animated } from "react-spring"

// reusable wrapper needed because passing refs to ...
// ... animated.section elements cause infinite re-renders
const AnimatedWrapper = forwardRef(({ styles, className, children }, ref) => (
  <animated.section className={className} style={styles}>
    <section ref={ref}>{children}</section>
  </animated.section>
))

export default AnimatedWrapper

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import AnimatedWrapper from "../components/animatedwrapper"
import ContactForm from "../components/contactform/contactform"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import useEnterAnimation from "../hooks/useEnterAnimation"
import getServiceIcon from "../utils/getserviceicon"
import MarketerSVG from "../assets/svgs/marketer.svg"
import "../styles/index.scss"

const servicesQuery = graphql`
  query {
    site {
      siteMetadata {
        services {
          title
          icon
        }
      }
    }
  }
`

export default function Index() {
  const [whyUsRef, whyUsStyles] = useEnterAnimation()
  const [servicesRef, servicesStyles] = useEnterAnimation()
  const [contactRef, contactStyles] = useEnterAnimation()

  const data = useStaticQuery(servicesQuery)
  const { services } = data.site.siteMetadata

  return (
    <Layout className="index-container">
      <SEO title="Home" />

      {/* HERO */}
      <section className="hero">
        <div className="hero__container">
          <div className="hero__divider--red" />
          <div className="hero__content">
            <div className="hero__text">
              <div className="hero__divider--yellow" />
              <h1>Creative solutions</h1>
              <p>Our staff will work with you to achieve your goals</p>
            </div>
            <Link to="/services" className="styled-button hero__button">
              Our Services
            </Link>
          </div>
        </div>
        <MarketerSVG />
      </section>

      {/* WHY US */}
      <AnimatedWrapper
        ref={whyUsRef}
        styles={whyUsStyles}
        className="whyus__container"
      >
        <div className="whyus__content">
          <p>
            We understand that choosing a marketing consultant can be difficult,
            but we are confident that our skills and experience can help your
            business achieve its goals.
          </p>
          <hr />
          <p>
            Our first step is to understand your business, so we can work
            together to create a successful, cost-effective solution that will
            deliver lasting results.
          </p>
          <Link to="/contact" className="styled-button whyus__button">
            Contact Us Now
          </Link>
        </div>
      </AnimatedWrapper>

      {/* SERVICES */}
      <AnimatedWrapper
        ref={servicesRef}
        styles={servicesStyles}
        className="services__container"
      >
        <h2>Our Services</h2>
        <p>Let's find the best solution for you</p>
        <hr />
        <div className="services__list">
          {services.map(({ title, icon }) => (
            <div className="services__item" key={title}>
              <h3>{title}</h3>
              {getServiceIcon(icon)}
            </div>
          ))}
        </div>
        <Link to="/services" className="styled-button services__button">
          Read More
        </Link>
      </AnimatedWrapper>

      {/* CONTACT */}
      <AnimatedWrapper
        ref={contactRef}
        styles={contactStyles}
        className="contact__container"
      >
        <ContactForm />
      </AnimatedWrapper>
    </Layout>
  )
}

import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { useSpring, config } from "react-spring"

export default function useEnterAnimation() {
  const [ref, inView] = useInView({ triggerOnce: true })
  const [styles, set] = useSpring(() => ({
    config: config.gentle,
    opacity: 0,
    transform: "translateY(40px)",
  }))

  useEffect(() => {
    if (!inView) return
    set({ opacity: 1, transform: "translateY(0px)" })
  }, [inView, set])

  return [ref, styles]
}
